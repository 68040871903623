import authPlugin from '@daangn/plantae-plugin-auth'
import {
  getCurrentEnvironment,
  karrotBridge,
} from '../../bridge/utils/bridgeMaker'

const plantaeAuthPlugin = ({
  fallbackAuthToken,
}: {
  fallbackAuthToken: string
}) => {
  const isApp =
    getCurrentEnvironment().isAndroid || getCurrentEnvironment().isIOS
  return authPlugin({
    bridge: karrotBridge,
    options: {
      fallbackAuthToken: isApp || {
        value: fallbackAuthToken ?? undefined, //local 환경 테스트
      },
    },
  })
}

export default plantaeAuthPlugin
