import { historySyncPlugin } from '@stackflow/plugin-history-sync'
import { basicUIPlugin } from '@stackflow/plugin-basic-ui'
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic'
import { stackflow } from '@stackflow/react'
import { devtoolsPlugin } from '@stackflow/plugin-devtools'
import { vars } from '@seed-design/design-token'
import type { ClientPlatformType } from '../bridge/utils/bridgeMaker'
import { activities } from './SearchActivities'
import { searchStackDepthChangePlugin } from './plugin/searchStackDepthChangePlugin'
import { searchKarrotAnalyticsPlugin } from './plugin/searchKarrotAnalyticsPlugin'
import { iOSScrollToTopPlugin } from './plugin/searchIOSScrollToTopPlugin'
import { STAGE } from '../_app/constants/common'
import { SEARCH_WEBVIEW_ROUTES } from '@daangn/search-sdk'

export const stackflowThemeBy = (environment: ClientPlatformType) => {
  if (environment === 'Cupertino') {
    return 'cupertino'
  }
  if (environment === 'Android') {
    return 'android'
  }
  // Web
  return 'cupertino'
}

const developmentOnlyPlugins = () => {
  if (STAGE === 'alpha' || STAGE === 'development') {
    return [devtoolsPlugin()]
  }
  return []
}

export const { Stack, useFlow } = stackflow({
  transitionDuration: 350,
  activities: activities,
  plugins: [
    basicUIPlugin(({ initialContext }) => ({
      theme: initialContext.theme,
      backgroundColor: vars.$semantic.color.paperDefault,
    })),
    basicRendererPlugin(),
    historySyncPlugin({
      useHash: false,
      routes: {
        GraphQL: '/___graphql',
        ...SEARCH_WEBVIEW_ROUTES,
      },
      fallbackActivity: () => 'Home',
    }),
    searchStackDepthChangePlugin(),
    searchKarrotAnalyticsPlugin(),
    iOSScrollToTopPlugin(),
    ...developmentOnlyPlugins(),
  ],
})
