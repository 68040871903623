import type { ReactNode } from 'react'
import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { STAGE } from '../constants/common'
import { useFoundation } from './FoundationProvider'
import { useVConsole } from '../../__development/vconsole/useVConsole'
import { VisualiseToggleButton } from '@daangn/webview-impression'
import { appBuildType } from '../utils/userAgent'
import { createRoot } from 'react-dom/client'

interface SupportToolsProviderProps {
  children: ReactNode
}

// const useClarity = () => {
//   useEffectMountOnce(() => {
//     if (STAGE !== 'production') {
//       return
//     }
//
//     const scriptTag = document.createElement('script')
//     scriptTag.type = 'text/javascript'
//     scriptTag.innerHTML = `
//       (function(c,l,a,r,i,t,y){
//         c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
//         t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
//         y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
//       })(window, document, "clarity", "script", "${CLARITY_PROJECT_ID}");
//   `
//
//     const headTag = document.querySelector('head')
//     headTag?.appendChild(scriptTag)
//   })
// }

const useSentryFoundation = () => {
  const { app, user } = useFoundation()
  useEffect(() => {
    if ('production' !== STAGE) {
      return
    }

    Sentry.setUser({
      id: user.id.toString(),
    })
    Sentry.setTags({
      appVersion: app.version,
    })
  }, [app.version, user.id])
}
const useImpressionVisualize = () => {
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      ('development' === STAGE ||
        appBuildType(window.navigator.userAgent).isDebugApp)
    ) {
      const div = document.createElement('div')
      div.id = 'impression-visualize'
      document.body.appendChild(div)
      const visualizeRoot = createRoot(div)
      visualizeRoot.render(<VisualiseToggleButton />)
    }
  }, [])
}

const SupportToolsProvider = (props: SupportToolsProviderProps) => {
  useVConsole()
  // T-20850 필요한 시점에 다시 적용
  // useClarity()
  useSentryFoundation()
  useImpressionVisualize()

  return <>{props.children}</>
}

export default SupportToolsProvider
