import { IS_ENV_SSR } from '../../_app/constants/common'
import {
  getCurrentEnvironment,
  karrotBridge,
} from '../../bridge/utils/bridgeMaker'
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics'

export const searchKarrotAnalyticsPlugin = () => {
  if (IS_ENV_SSR || getCurrentEnvironment().isWeb) {
    return () => ({
      key: 'do-not-applied-karrot-anlaytics-plugin',
    })
  }

  return karrotAnalyticsPlugin({
    bridge: karrotBridge,
    serviceName: 'search',
  })
}
