import { datadogRum } from '@datadog/browser-rum-slim'
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  STAGE,
  VERSION,
} from '../constants/common'
import { getRenderType } from './getRenderType'

interface DatadogRumContext {
  render: {
    type: 'csr' | 'ssr'
  }
}
export const initDatadogRum = (context: DatadogRumContext) => {
  if (STAGE !== 'production' && STAGE !== 'alpha') {
    return
  }
  const sessionSampleRate = STAGE === 'production' ? 0.2 : 50
  // datadog
  datadogRum.init({
    env: STAGE,
    version: VERSION,
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'search-webview',
    sessionSampleRate: sessionSampleRate, // 0.2% of sessions will be recorded
    sessionReplaySampleRate: 0, //@datadog/browser-rum-slim 패키지는 sessionReplay를 지원하지 않지만 명시적으로 0% 설정
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask', // sessionReplay를 안쓰고 있지만, 보수적으로 모든 항목에 대해 마스킹으로 기본 설정
  })

  datadogRum.setGlobalContextProperty('render', {
    type: context.render.type,
  })
}

export const createDatadogRumGlobalContext = () => {
  return {
    render: {
      type: getRenderType(),
    },
  }
}
