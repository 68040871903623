import type { StateCreatorWithDevtools } from '../../__development/zustand'

export type SliceCreatorWithInitState<T, U> = (
  injectedInitState?: T
) => StateCreatorWithDevtools<U>

export type InjectInitStateToSlice = <T, U extends object>({
  storeApis,
  sliceCreator,
  initState,
}: {
  storeApis: Parameters<StateCreatorWithDevtools<U>>
  sliceCreator: SliceCreatorWithInitState<T, U>
  initState?: T
}) => U

export const injectInitStateToSlice: InjectInitStateToSlice = ({
  storeApis,
  initState,
  sliceCreator,
}) => sliceCreator(initState)(...storeApis)
