import to from 'await-to-js'
import axios from 'axios'
import axiosRetry from 'axios-retry'

import {
  IS_ENV_SSR,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeInjectServerSideHeaderPlugin,
} from '../../plantae/plugins'

import plantaeSearchUserIdPlugin from '../../plantae/plugins/plantaeSearchUserIdPlugin'
import { InitializeApi } from '../../__codegen__/__openapi__/search-front-server'
import type { SearchExperimentReferrerType } from '../../referrer/types'
import { addExperimentXSearchHeader } from '../../_app/utils/addCustomHeader'

let serviceCache: ServiceV4InitializeType | null = null

export type ServiceV4InitializeType = ReturnType<typeof ServiceV4Initialize>

export function getServiceV4Initialize({
  app,
  user,
}: {
  app: AppType
  user: UserType
}) {
  if (serviceCache) {
    return serviceCache
  }

  return (serviceCache = ServiceV4Initialize({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    app,
    user,
  }))
}

const ServiceV4Initialize = ({
  baseUrl,
  app,
  user,
}: {
  baseUrl: string
  app: AppType
  user: UserType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin()]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({ userAgent: app.userAgent }),
          plantaeSearchUserIdPlugin({ userId: user.id }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
  })

  const client = new InitializeApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     * 검색 초기 데이터 가져오기(실험정보, impression 정보)
     */
    async getAppInitialize() {
      const [error, response] = await to(client.apiV1InitializeGet())

      if (error || !response?.data) {
        captureException(error)
        return null
      }

      if ('status' in response.data) {
        return null
      }

      return response.data
    },
    /**
     * 실험 커스텀 트리거가 되었을 경우 실험 정보를 가져오기 위한 API
     */
    async getSearchExperiment(referrer: SearchExperimentReferrerType) {
      const [error, response] = await to(
        client.apiV1CustomExperimentGet(
          {}, // No query parameters
          {
            headers: addExperimentXSearchHeader({
              ...referrer,
            }),
          }
        )
      )

      if (error || !response?.data) {
        captureException(error)
        return null
      }

      if ('status' in response.data) {
        return null
      }

      return response.data
    },
  }
}
