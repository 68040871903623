import type { Plugin } from 'plantae'

export default function plantaeInjectServerSideHeaderPlugin(): Plugin {
  return {
    name: 'plugin-inject-server-side-header',
    hooks: {
      beforeRequest: async (req: Request) => {
        const headers =
          globalThis.__KARROT_SEARCH_INJECTED_SSR__?.BYPASS_HEADER || []

        const requestWithWebviewLoadHeader = headers.reduce(
          (updatedReq, headerObject) => {
            if (headerObject) {
              Object.entries(headerObject).forEach(([key, value]) => {
                if (value !== undefined) {
                  updatedReq.headers.set(key, value)
                }
              })
            }
            return updatedReq
          },
          req
        )

        return requestWithWebviewLoadHeader
      },
    },
  }
}
