import { createStore } from 'zustand'
import { devtools } from 'zustand/middleware'
import type {
  ExperimentSliceType,
  ExperimentStateType,
} from '../../experiment/stores/ExperimentSlice'
import { experimentSlice } from '../../experiment/stores/ExperimentSlice'

import type { GlobalStoreActionTypes } from './GlobalStoreActions'
import { globalStoreActions } from './GlobalStoreActions'
import { placeMapSlice } from '../../place/stores/placeMapSlice'
import { STAGE } from '../constants/common'
import { lastOpenedSchemeTargetSlice } from './LastOpenedSchemeTargetSlice'
import { snackbarSlice } from '../components/snackbar/snackbarSlice'
import { injectInitStateToSlice } from '../../__development/zustand/injectInitStateToSlice'

type SliceTypes = ReturnType<typeof placeMapSlice> &
  ReturnType<typeof lastOpenedSchemeTargetSlice> &
  ExperimentSliceType &
  ReturnType<typeof snackbarSlice>

export type GlobalState = SliceTypes & {
  actions: GlobalStoreActionTypes
}

export type GlobalStore = ReturnType<typeof createGlobalStore>

type InitGlobalState = {
  experiment: {
    state: ExperimentStateType
  }
}

export interface GlobalStoreProps {
  initGlobalState: Partial<InitGlobalState>
}

export const createGlobalStore = ({ initGlobalState }: GlobalStoreProps) => {
  return createStore<GlobalState>()(
    devtools(
      (...args) => {
        return {
          // TODO: Slices of state
          ...placeMapSlice(...args),
          ...lastOpenedSchemeTargetSlice(...args),
          ...injectInitStateToSlice({
            initState: initGlobalState.experiment?.state,
            sliceCreator: experimentSlice,
            storeApis: args,
          }),
          ...snackbarSlice(...args),
          // Actions
          actions: globalStoreActions(...args),
        }
      },
      {
        enabled: STAGE === 'development',
        name: 'GlobalStore',
      }
    )
  )
}
