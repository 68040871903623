import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'
import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import type { SearchReferrerType } from '../../referrer/types'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeInjectServerSideHeaderPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import { convertCollectionMetaToConnection } from '../../_app/utils/convertCollectionToConnection'
import {
  FleamarketApi,
  type ApiV1FleamarketSearchPostRequest,
} from '../../__codegen__/__openapi__/search-front-server'
import { addXSearchHeaders } from '../../_app/utils/addCustomHeader'

/**
 * 중고거래 검색 결과 요청 파라미터
 */
interface FleaMarketsSearchRequestParamsType
  extends ApiV1FleamarketSearchPostRequest {
  referrer: SearchReferrerType
}

let serviceCache: ServiceSearchFleaMarketType | null = null

export type ServiceSearchFleaMarketType = ReturnType<
  typeof ServiceSearchFleaMarket
>

export function getServiceSearchFleaMarket({
  user,
  app,
}: {
  user: UserType
  app: AppType
}) {
  if (serviceCache) {
    return serviceCache
  }

  return (serviceCache = ServiceSearchFleaMarket({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    user,
    app,
  }))
}

const ServiceSearchFleaMarket = ({
  baseUrl,
  user,
  app,
}: {
  baseUrl: string
  user: UserType
  app: AppType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  const axiosInstance = axios.create({
    headers: headers,
    timeout: NETWORK_TIMEOUT,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin()]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({ userAgent: app.userAgent }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new FleamarketApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     * 중고거래 목록 조회
     */
    getFleaMarkets: async ({
      query,
      regionId,
      pageSize,
      pageToken,
      sort,
      regionRange,
      categoryIds,
      priceGte,
      priceLte,
      withoutCompleted,
      isFreeShareEvent,
      referrer,
    }: FleaMarketsSearchRequestParamsType) => {
      const [error, response] = await to(
        client.apiV1FleamarketSearchPost(
          {
            apiV1FleamarketSearchPostRequest: {
              regionId: regionId,
              pageSize: pageSize,
              query: query,
              pageToken: pageToken,
              sort: sort,
              priceGte: priceGte,
              priceLte: priceLte,
              regionRange: regionRange,
              categoryIds: categoryIds,
              withoutCompleted: withoutCompleted,
              isFreeShareEvent: isFreeShareEvent,
            },
          },
          {
            headers: addXSearchHeaders(referrer),
          }
        )
      )

      if (error) {
        captureException(error)
        return null
      }

      if (!response?.data) {
        return null
      }

      if ('error' in response.data) {
        return {
          connection: convertCollectionMetaToConnection([]),
          [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
            response.headers
          ),
          error: response.data.details,
        }
      }

      const connection = convertCollectionMetaToConnection(
        response.data.documents
      )

      return {
        connection,
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          response.headers
        ),
      }
    },
    /**
     * 중고거래 연관검색어(TOP, FILTER 용)
     * @param keyword
     * @param regionId
     * @param referrer
     */
    async getFleamarketRelatedKeywords({
      regionId,
      keyword,
      referrer,
    }: {
      regionId: number
      keyword: string
      referrer: SearchReferrerType
    }) {
      const [error, response] = await to(
        client.apiV1FleamarketRelatedKeywordGet(
          {
            regionId: String(regionId),
            keyword,
          },
          {
            headers: addXSearchHeaders({
              ...referrer,
            }),
          }
        )
      )

      if (error || !response?.data) {
        captureException(error)
        return null
      }

      if ('status' in response.data) {
        return null
      }

      return response.data.keywords ?? []
    },

    /**
     * 중고거래 검색 필터 추천 (카테고리, 가격)
     */
    async getFleaMarketFilterRecommend({
      query,
      regionId,
      referrer,
    }: {
      query: string
      regionId: number
      referrer: SearchReferrerType
    }) {
      const [error, response] = await to(
        client.apiV1FleamarketRecommendedFilterGet(
          {
            regionId: String(regionId),
            keyword: query,
          },
          {
            headers: addXSearchHeaders({
              ...referrer,
            }),
          }
        )
      )

      if (error || !response?.data) {
        captureException(error)
        return null
      }

      if ('status' in response.data) {
        return null
      }

      return {
        ...response.data,
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          response.headers
        ),
      }
    },
  }
}
