import type { ReactNode } from 'react'
import { Suspense } from 'react'
import SearchDiscoverSkeleton from '../search-discover/SearchDiscoverSkeleton'
import PageSearchResultSkeleton from '../../../_pages/PageSearchResultSkeleton'
import {
  executeByEnvironment,
  karrotBridge,
} from '../../../bridge/utils/bridgeMaker'
import PageHomeSearchSkeleton from '../../../_pages/PageHomeSearchSkeleton'
import { IS_ENV_SSR } from '../../constants/common'

interface EntryPointSkeletonProps {
  url: string
  children: ReactNode
}
const parseURL = (url?: string) => {
  if (!url) {
    return { pathname: '', search: '' }
  }
  const fullUrl = new URL(url, 'file://')
  return { pathname: fullUrl.pathname, search: fullUrl.search }
}

const EntryPointSkeleton = (props: EntryPointSkeletonProps) => {
  const { pathname, search } = parseURL(props.url)
  const path = pathname || window.location.pathname
  const locationSearch = search || (IS_ENV_SSR ? '' : document.location.search)
  const params = new URLSearchParams(locationSearch)
  // @deprecated 로직
  const isDeprecatedSearchDiscoverPath = path.startsWith('/search/discover')
  if (isDeprecatedSearchDiscoverPath) {
    return (
      <Suspense fallback={<SearchDiscoverSkeleton />}>
        {props.children}
      </Suspense>
    )
  }

  const isDeprecatedSearchResultPath = path.startsWith('/search')
  const queryValue = params.get('query')
  // @deprecated 로직
  if (isDeprecatedSearchResultPath && queryValue) {
    return (
      <Suspense
        fallback={
          <PageSearchResultSkeleton
            query={queryValue}
            onBackClick={() => {
              executeByEnvironment({
                onApp: () => karrotBridge.closeRouter({}),
                onWeb: () => window.history.back(),
              })
            }}
          />
        }
      >
        {props.children}
      </Suspense>
    )
  }

  const isHomePath = path.startsWith('/home')
  if (isHomePath) {
    return (
      <Suspense
        fallback={
          <PageHomeSearchSkeleton
            query={queryValue || ''}
            onBackClick={() => {
              executeByEnvironment({
                onApp: () => karrotBridge.closeRouter({}),
                onWeb: () => window.history.back(),
              })
            }}
          />
        }
      >
        {props.children}
      </Suspense>
    )
  }
  return <Suspense fallback={null}>{props.children}</Suspense>
}

export default EntryPointSkeleton
