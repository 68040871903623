import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'

import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import type { SearchReferrerType } from '../../referrer/types'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeInjectServerSideHeaderPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import type { ApiV1IntegrateSearchPostRequest } from '../../__codegen__/__openapi__/search-front-server'
import { IntegrateApi } from '../../__codegen__/__openapi__/search-front-server'
import { addXSearchHeaders } from '../../_app/utils/addCustomHeader'

interface AggregatedV4SearchRequestParamsType
  extends ApiV1IntegrateSearchPostRequest {
  referrer: SearchReferrerType
}
let serviceCache: ServiceSearchAggregatedType | null = null
export type ServiceSearchAggregatedType = ReturnType<
  typeof ServiceSearchAggregated
>

export function getServiceSearchAggregated({
  user,
  app,
}: {
  user: UserType
  app: AppType
}) {
  if (serviceCache) {
    return serviceCache
  }

  return (serviceCache = ServiceSearchAggregated({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    user,
    app,
  }))
}

const ServiceSearchAggregated = ({
  baseUrl,
  user,
  app,
}: {
  baseUrl: string
  user: UserType
  app: AppType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  const axiosInstance = axios.create({
    headers: headers,
    timeout: NETWORK_TIMEOUT,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin()]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({ userAgent: app.userAgent }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new IntegrateApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     * 통합탭 결과 API
     */
    aggregatedAll: async ({
      query,
      regionId,
      useLocation,
      fleaMarket,
      karrotGroup,
      location,
      referrer,
    }: AggregatedV4SearchRequestParamsType) => {
      const [error, response] = await to(
        client.apiV1IntegrateSearchPost(
          {
            apiV1IntegrateSearchPostRequest: {
              query: query,
              regionId: regionId,
              useLocation: useLocation,
              location: location,
              fleaMarket: fleaMarket,
              karrotGroup,
            },
          },
          {
            headers: addXSearchHeaders(referrer),
          }
        )
      )

      if (error) {
        captureException(error)
        return null
      }

      if (!response?.data) {
        return null
      }

      if ('error' in response.data) {
        return {
          collectionRanks: undefined,
          fleaMarket: undefined,
          community: undefined,
          businessPosts: undefined,
          place: undefined,
          shortcut: undefined,
          job: undefined,
          advertisement: undefined,
          commerce: undefined,
          car: undefined,
          realty: undefined,
          group: undefined,
          localProfile: undefined,
          dspAdvertisement: undefined,
          catalogProductAd: undefined,
          [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
            response.headers
          ),
          error: response.data.details,
        }
      }

      return {
        ...response.data,
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          response.headers
        ),
      }
    },
    /**
     * 통합탭 모임 필터 - 가입 가능 지역 범위
     */
    async getRegionRanges({ regionId }: { regionId: number }) {
      const [error, resp] = await to(
        client.apiV1IntegrateKarrotGroupRegionRangesGet({
          regionId: regionId.toString(),
          rangeType: 'view',
          polygonFormat: 'geojson',
        })
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      if ('status' in resp.data) {
        return null
      }

      return resp.data
    },
  }
}
