import type { ReactNode } from 'react'
import {
  useState,
  useRef,
  useMemo,
  createContext,
  useContext,
  useLayoutEffect,
} from 'react'
import type { AppType, UserType } from './FoundationProvider'
import { useFoundation } from './FoundationProvider'
import { experimentStateMapper } from '../../experiment/utils/experimentStateMapper'
import { wrapForSuspense } from '../utils/wrapForSuspense'
import MaintenanceError from '../../error/class/MaintenanceError'
import { getServiceV4Initialize } from '../../services/initialize/ServiceV4Initialize'
import type {
  ApiV1InitializeGet200ResponseAd,
  ApiV1InitializeGet200ResponseExperimentUser,
  ApiV1InitializeGet200Response,
} from '../../__codegen__/__openapi__/search-front-server'

export type InitializeType = ApiV1InitializeGet200Response

type ImpressionMemory = {
  coolTime?: number
  exposureTime?: number
  threshold?: number
}
const ImpressionContext = createContext<ImpressionMemory | null>(null)

const ExperimentContext =
  createContext<ApiV1InitializeGet200ResponseExperimentUser | null>(null)

const AdInitializeContext =
  createContext<ApiV1InitializeGet200ResponseAd | null>(null)

function makeInitializeResource(resource: { user: UserType; app: AppType }) {
  const promise = (async () => {
    try {
      const serviceV4Initialize = getServiceV4Initialize({
        app: resource.app,
        user: resource.user,
      })

      return await serviceV4Initialize.getAppInitialize()
    } catch (e) {
      if (e instanceof MaintenanceError) {
        throw e
      }

      return null
    }
  })()

  return wrapForSuspense(promise)
}

const InitializeResourceProvider = (props: {
  resource: ReturnType<typeof makeInitializeResource>
  children: ReactNode
}) => {
  const appInitializeData = props.resource.read() ?? null

  const impressionMemories: ImpressionMemory = useMemo(() => {
    if (!appInitializeData?.impression) {
      return {}
    }

    return {
      coolTime: appInitializeData.impression?.coolTimeSecond * 1000,
      exposureTime: appInitializeData.impression?.exposureDuration * 1000,
      threshold: appInitializeData.impression?.impressionRatio,
    }
  }, [appInitializeData?.impression])

  const userInfoMemories: ApiV1InitializeGet200ResponseExperimentUser =
    useMemo(() => {
      if (!appInitializeData?.experimentUser) {
        return {
          experimentSegments: [],
          headerSegmentKey: '',
          headerSegmentValue: '',
        }
      }

      return appInitializeData?.experimentUser
    }, [appInitializeData?.experimentUser])

  const adInfoMemories: ApiV1InitializeGet200ResponseAd = useMemo(() => {
    if (!appInitializeData?.ad) {
      return {
        dspLoggingSampleRate: 0,
      }
    }
    return appInitializeData.ad
  }, [appInitializeData?.ad])

  return (
    <ExperimentContextProvider value={userInfoMemories}>
      <ImpressionContextProvider value={impressionMemories}>
        <AdInitializeContextProvider value={adInfoMemories}>
          {props.children}
        </AdInitializeContextProvider>
      </ImpressionContextProvider>
    </ExperimentContextProvider>
  )
}

const AdInitializeContextProvider = (props: {
  children: ReactNode
  value: ApiV1InitializeGet200ResponseAd
}) => {
  return (
    <AdInitializeContext.Provider value={props.value}>
      {props.children}
    </AdInitializeContext.Provider>
  )
}

const ExperimentContextProvider = (props: {
  children: ReactNode
  value: ApiV1InitializeGet200ResponseExperimentUser
}) => {
  return (
    <ExperimentContext.Provider value={props.value}>
      {props.children}
    </ExperimentContext.Provider>
  )
}

interface ImpressionContextProviderProps {
  children: ReactNode
  value: ImpressionMemory
}

const ImpressionContextProvider = (props: ImpressionContextProviderProps) => {
  return (
    <ImpressionContext.Provider value={props.value}>
      {props.children}
    </ImpressionContext.Provider>
  )
}

const InitializeProvider = (props: {
  initialize: InitializeType
  children: ReactNode
}) => {
  const impressionMemories: ImpressionMemory = useMemo(() => {
    if (!props.initialize?.impression) {
      return {}
    }

    return {
      coolTime: props.initialize.impression?.coolTimeSecond * 1000,
      exposureTime: props.initialize.impression?.exposureDuration * 1000,
      threshold: props.initialize.impression?.impressionRatio,
    }
  }, [props.initialize?.impression])

  const userInfoMemories: ApiV1InitializeGet200ResponseExperimentUser =
    useMemo(() => {
      if (!props.initialize?.experimentUser) {
        return {
          experimentSegments: [],
          headerSegmentKey: '',
          headerSegmentValue: '',
        }
      }

      return props.initialize?.experimentUser
    }, [props.initialize?.experimentUser])

  const adInfoMemories: ApiV1InitializeGet200ResponseAd = useMemo(() => {
    if (!props.initialize?.ad) {
      return {
        dspLoggingSampleRate: 0,
      }
    }
    return props.initialize.ad
  }, [props.initialize?.ad])

  return (
    <ExperimentContextProvider value={userInfoMemories}>
      <ImpressionContextProvider value={impressionMemories}>
        <AdInitializeContextProvider value={adInfoMemories}>
          {props.children}
        </AdInitializeContextProvider>
      </ImpressionContextProvider>
    </ExperimentContextProvider>
  )
}
const InitializeResourceFetchProvider = (props: { children: ReactNode }) => {
  const { app, user } = useFoundation()
  const [resource, setResource] = useState<ReturnType<
    typeof makeInitializeResource
  > | null>(null)
  const isMountOnce = useRef<boolean>(false)

  useLayoutEffect(() => {
    if (isMountOnce.current) {
      return
    }

    setResource(makeInitializeResource({ app, user }))

    return () => {
      if (!app || !user) {
        return
      }

      isMountOnce.current = true
    }
  }, [app, user])

  // TODO: Loading
  if (!resource) {
    return null
  }

  return (
    <InitializeResourceProvider resource={resource}>
      {props.children}
    </InitializeResourceProvider>
  )
}

export const AppInitializeProvider = (props: {
  initialize?: InitializeType
  children: ReactNode
}) => {
  if (props.initialize) {
    return (
      <InitializeProvider initialize={props.initialize}>
        {props.children}
      </InitializeProvider>
    )
  }

  return (
    <InitializeResourceFetchProvider>
      {props.children}
    </InitializeResourceFetchProvider>
  )
}

export const useExperimentContext = () => {
  const state = useContext(ExperimentContext)

  if (!state) {
    throw new Error(
      'useExperimentContext must be used within a ExperimentContextProvider'
    )
  }

  return experimentStateMapper(state)
}

export const useImpressionContext = () => {
  const state = useContext(ImpressionContext)

  return {
    coolTime: state?.coolTime,
    exposureTime: state?.exposureTime,
    threshold: state?.threshold,
  }
}

export const useAdInitializeContext = () => {
  const state = useContext(AdInitializeContext)
  return {
    DSPLoggingSampleRate: state?.dspLoggingSampleRate || 0,
  }
}
