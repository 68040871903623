import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'

import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import type { SearchReferrerType } from '../../referrer/types'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeInjectServerSideHeaderPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import { convertCollectionToConnection } from '../../_app/utils/convertCollectionToConnection'
import type { ApiV1UserSearchPostRequest } from '../../__codegen__/__openapi__/search-front-server'
import { UserApi } from '../../__codegen__/__openapi__/search-front-server'
import { addXSearchHeaders } from '../../_app/utils/addCustomHeader'

interface UsersV4SearchRequestParamsType extends ApiV1UserSearchPostRequest {
  referrer: SearchReferrerType
}

let serviceCache: ServiceUserType | null = null
export type ServiceUserType = ReturnType<typeof ServiceUser>

export const getServiceUser = ({
  user,
  app,
}: {
  user: UserType
  app: AppType
}) => {
  if (serviceCache) {
    return serviceCache
  }
  return (serviceCache = ServiceUser({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    user,
    app,
  }))
}

const ServiceUser = ({
  baseUrl,
  user,
  app,
}: {
  baseUrl: string
  user: UserType
  app: AppType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: NETWORK_TIMEOUT,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin()]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({ userAgent: app.userAgent }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new UserApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     * 유저 목록 조회
     */
    getUsers: async ({
      query,
      regionId,
      pageSize,
      pageToken,
      referrer,
    }: UsersV4SearchRequestParamsType) => {
      const [error, response] = await to(
        client.apiV1UserSearchPost(
          {
            apiV1UserSearchPostRequest: {
              query,
              regionId,
              pageSize,
              pageToken,
            },
          },
          {
            headers: addXSearchHeaders(referrer),
          }
        )
      )

      if (error || !response?.data) {
        captureException(error)
        throw new Error(
          error?.message ?? '[ServiceUser: getUsers]: unexpected error'
        )
      }

      if ('error' in response.data) {
        return {
          connection: convertCollectionToConnection([]),
          [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
            response.headers
          ),
          error: response.data.details,
        }
      }

      const connection = convertCollectionToConnection(response.data.documents)

      return {
        connection,
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          response.headers
        ),
      }
    },
  }
}
